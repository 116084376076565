<template>
  <div v-if="$store.getters.ready">
    <Header />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row justify-content-md-center mt-3">
            <div class="col-md-8 col-lg-6 col-xl-5">

              <div class="widget-holder rounded">
                <div class="widget-bg">
                  <div class="widget-body">

                    <h2 class="mb-5 text-center">Payment Method</h2>

                    <div class="form-row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="col-form-label pt-0">Billing Address</label>
                          <input type="text" class="form-control" v-model="form.name" maxlength="100" placeholder="Cardholder Name">
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <input type="text" class="form-control" v-model="form.company" maxlength="100" placeholder="Company">
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <input type="text" class="form-control" v-model="form.address1" maxlength="100" placeholder="Address">
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <input type="text" class="form-control" v-model="form.address2" maxlength="100">
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="form-group">
                          <input type="text" class="form-control" v-model="form.city" maxlength="50" placeholder="City">
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <input type="text" class="form-control" v-model="form.state" maxlength="50" :placeholder="form.country === 'CA' ? 'Province' : 'State'">
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <input type="text" class="form-control" v-model="form.zip" maxlength="50" :placeholder="form.country === 'CA' ? 'Postal Code' : 'Zip'">
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <select v-model="form.country" class="form-control custom-select">
                            <option value="US">United States</option>
                            <option value="CA">Canada</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <hr class="my-4">

                    <div class="form-row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="col-form-label pt-0">Credit Card Number</label>
                          <div class="input-group">
                            <div ref="cc" class="stripe-form-control form-control"></div>
                            <div class="input-group-append"><span class="input-group-text"><i class="fal fa-lock-alt fa-lg text-primary"></i></span></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-form-label text-center">Expiration Date</label>
                          <div ref="ccexpr" class="stripe-form-control form-control"></div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-form-label">Security Code</label>
                          <div class="input-group">
                            <div ref="ccv" class="stripe-form-control form-control"></div>
                            <div class="input-group-append"><span class="input-group-text"><i class="fal fa-info-circle fa-lg text-primary" v-b-tooltip.hover title="3-digit security code on the back of your card"></i></span></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group mx-sm-3 px-sm-5 pt-4 mt-2 mb-2">
                      <button type="submit" class="btn btn-block btn-rounded btn-md btn-primary text-uppercase fw-600 ripple" @click="updateCard()" :disabled="waiting"><i v-if="waiting" class="fal fa-spinner fa-spin mr-2" style="margin-left: -22px;"></i>Save Changes</button>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { TooltipPlugin } from 'bootstrap-vue';
import { loadStripe } from '@stripe/stripe-js';
import Header from '@/components/Header.vue';
import toast from '@/modules/toast';

Vue.use(TooltipPlugin);

let stripe;
let elements;

export default {
  data() {
    return {
      settings: {},
      form: {},
      waiting: false,
    };
  },
  methods: {
    async updateCard() {
      this.waiting = true;
      // hardcode frequency
      this.form.frequency = this.$config.billing_frequency;
      // stripe
      const addr = {
        name: this.form.name,
        address_line1: this.form.address1,
        address_line2: this.form.address2,
        address_city: this.form.city,
        address_state: this.form.state,
        address_zip: this.form.zip,
        address_country: this.form.country,
      };
      stripe.createToken(elements.getElement('cardNumber'), addr)
        .then((res) => {
          if (res.error) {
            toast.danger(this, res.error.message);
            this.waiting = false;
            return;
          }
          this.form.card_token = res.token.id;
          // save
          this.$api.post('/profile/billing', this.form)
            .then(() => {
              this.waiting = false;
              this.$auth.profile.update_billing = false;
              if (this.$auth.profile.verify_email) {
                this.$router.push('/profile/verify');
              } else {
                this.$router.push('/dashboard');
              }
            })
            .catch((e) => {
              toast.danger(this, e.response.data.message);
              this.waiting = false;
            });
        });
    },
    setSettings(v) {
      this.settings = v;
      // copy to form
      this.form = JSON.parse(JSON.stringify(v));
      if (this.form.country === '') {
        this.form.country = 'US';
      }
      delete this.form.tx;
    },
  },
  async mounted() {
    if (!this.$auth.profile.enable_billing || !this.$auth.profile.update_billing) {
      this.$router.push('/dashboard');
      return;
    }
    try {
      this.setSettings((await this.$api.get('/profile/billing')).data);
      this.$store.commit('ready', true);
      // stripe
      stripe = await loadStripe(this.settings.stripe_key);
      elements = stripe.elements();
      const style = {
        base: {
          color: '#444',
          fontSize: '14px',
          lineHeight: '1.57142857',
          '::placeholder': { color: '#bbb' },
        },
      };
      (elements.create('cardNumber', { style })).mount(this.$refs.cc);
      (elements.create('cardExpiry', { style })).mount(this.$refs.ccexpr);
      (elements.create('cardCvc', { style })).mount(this.$refs.ccv);
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  components: {
    Header,
  },
};
</script>
